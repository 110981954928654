import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getVideos } from "../../util/api";
import { Loading, LoadingText } from "../layout/Loading";

export default function VideoStream(props) {

  const [videos, setVideos] = useState({});
  const { id, profileId } = useParams();

  useEffect(() => {
    props.setSiteTitle("Video Streams");

    const load = async (profileId) => {
      const vido = await getVideos(profileId);
      if (vido != null) {
        setVideos(values => ({...vido, contents: JSON.parse(vido.contents)}));
      } 
    }

    load(profileId);

  }, [props, profileId]);

  const navigate = useNavigate();
  const handleHistroy = () => { navigate(-1); }

  return (<main>
    <div className="container">
      <div className="row">
        <div className="col-12">

          <div className="row g-0 mb-4">
            <div className="col-xl-12 col-xxl-12">

              <div className="card card-body p-0 rounded-end-lg-0 position-relative h-100">

                <div className="card-image">
                  <div className="overflow-hidden fullscreen-video w-100">

                    <div className="player-wrapper card-img-top overflow-hidden" dangerouslySetInnerHTML={{__html:  videos.mainVideo} }>

                    </div>

                  </div>
                </div>

                <div className="card-body">
                  <div className="d-xxl-flex justify-content-between mb-3">
                    <div className="mb-2 mb-xxl-0">
                      <a className="btn btn-primary position-fixed end-0 bottom-0 me-5 mb-5" href="#!" onClick={handleHistroy}> <i className="fa-solid fa-angle-left"></i> Back</a>

                      <h4>{videos.mainTitle}</h4>
                      <div className="d-flex mt-3 align-items-center">

                        <div className="avatar me-2">
                          <img className="avatar-img rounded-circle" src="https://yt3.ggpht.com/HB2DOepwz1dsa1M83WFFBWJr510x4Qz24FOApKoPN-K8jsBTZOtf76NXJ6pWHpnB0hTVJBart-s=s88-c-k-c0x00ffffff-no-rj" alt="" />
                        </div>

                        <div>
                          <h6 className="mb-0"> <a href="#!">Moh'd Rimsan </a> </h6>
                          <nav className="nav nav-divider small">
                            <span className="nav-item">12 views</span>
                            <span className="nav-item">7 Feb, 2021</span>
                          </nav>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-start">
                      <div className="dropdown">
                        <a className="nav nav-link mb-0" href="!#" id="cardShareAction" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-reply-fill flip-horizontal ps-1"></i>Share (2)
                        </a>

                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction">
                          <li><a className="dropdown-item" href="!#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</a></li>
                          <li><a className="dropdown-item" href="!#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </a></li>
                          <li><a className="dropdown-item" href="!#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</a></li>
                          <li><a className="dropdown-item" href="!#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</a></li>
                          <li><hr className="dropdown-divider" /></li>
                          <li><a className="dropdown-item" href="!#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</a></li>
                        </ul>
                      </div>
                      <div className="btn-group ms-2" role="group" aria-label="Basic radio toggle button group">

                        <input type="radio" className="btn-check" name="article1" id="articleup1" />
                        <label className="btn btn-outline-light btn-sm mb-0" htmlFor="articleup1"><i className="fa-solid fa-thumbs-up me-1"></i> 1</label>

                        <input type="radio" className="btn-check" name="article1" id="articledown1" defaultChecked="" />
                        <label className="btn btn-outline-light btn-sm mb-0" htmlFor="articledown1"> 0 <i className="fa-solid fa-thumbs-down ms-1"></i></label>
                      </div>
                    </div>
                  </div>

               

                </div>
              </div>

            </div>
            <div className="col-xl-4 col-xxl-3 d-none">
              <div className="card rounded-start-lg-0 border-start-0 h-100">
                <div className="card-header border-0 pb-0">
                  <h5 className="mb-4"> 0 Comments</h5>
                </div>
                <div className="card-body pt-0 h-400px">


                </div>
                <div className="card-footer border-0 pt-0">

                  <div className="d-flex">

                    <div className="avatar avatar-xs me-2">
                      <a href="#!"> <img className="avatar-img rounded-circle" src="https://yt3.ggpht.com/HB2DOepwz1dsa1M83WFFBWJr510x4Qz24FOApKoPN-K8jsBTZOtf76NXJ6pWHpnB0hTVJBart-s=s88-c-k-c0x00ffffff-no-rj" alt="" /> </a>
                    </div>

                    <form className="position-relative w-100">
                      <textarea className="form-control pe-4 bg-light" data-autoresize="" rows="1" placeholder="Add a comment..."></textarea>

                      <div className="position-absolute top-0 end-0">
                        <button className="btn" type="button">🙂</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>

        <div className="row g-3 mb-4">
          <div className="col-12 mt-4">
            <h5>More related video </h5>
          </div>

          {(videos.contents) ? videos?.contents?.map((value, index) => {
            return <div key={index} className="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
              <div className="card p-0 position-relative h-100">

                <div className="card-image">
                  <img className="card-img-top" src={value.image} alt="" />

                  <div className="position-absolute top-50 start-50 translate-middle">
                    <a className="icon-md bg-danger text-white rounded-circle" href="!#"> <i className="bi bi-play-fill fs-5"> </i> </a>
                  </div>

                  <div className="position-absolute bottom-0 start-0 p-3 d-flex w-100">
                    <span className="bg-dark bg-opacity-50 px-2 rounded text-white small">{value.duration}</span>
                    <span className="bg-dark bg-opacity-50 px-2 rounded text-white small ms-auto"><i className="fa-solid fa-heart"></i></span>
                  </div>
                </div>

                <div className="card-body">
                  <h6> <a className="stretched-link" target="_blank" href={value.link}>{value.title}</a> </h6>
                  <span className="small">{value.view}</span>
                </div>
              </div>

            </div>
          }) : <p>not updated</p> }


        </div>
      </div>
    </div>
  </main>);
}