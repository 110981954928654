import GoogleButton from "../../login/GoogleButton";
import Users from "./Users";

export default function Intro() {

    return (<><section className="py-4 py-sm-5 mb-4">

        <div className="position-absolute top-0 start-0 mt-n5 pt-n5">
            <img className="h-300px blur-9" src="https://rimsan.me/images/front/06.svg" alt="" />
        </div>

        <div className="container">
            <div className="row">
                <div className="col-lg-10 ms-auto">
                    <div className="row g-4 align-items-center">
                        <div className="col-md-4 col-lg-4 position-relative">

                            <img className="rounded-circle img-fluid d-none d-md-block" src="https://rimsan.me/images/front/intro-ani.gif" alt="" />

                            <div className="position-absolute top-50 start-0 translate-middle d-none d-lg-block">

                                <div className="bg-mode border p-3 rounded-3 rounded-start-top-0 d-flex align-items-center mb-3">

                                    <div className="avatar avatar-xs me-3">
                                        <a href="!#"> <img className="avatar-img rounded-circle" src="https://rimsan.me/images/front/12.jpg" alt="Best Profile" /> </a>
                                    </div>

                                    <div className="d-flex">
                                        <h6 className="mb-0 ">Best Profile </h6>
                                        <span className="ms-2">👩🏻‍💻</span>
                                    </div>
                                </div>

                                <div className="bg-mode border p-3 rounded-3 rounded-start-top-0 d-flex align-items-center mb-3">

                                    <div className="avatar avatar-xs me-3">
                                        <a href="!#"> <img className="avatar-img rounded-circle" src="https://rimsan.me/images/front/10.jpg" alt="Portfolio & Use cases" /> </a>
                                    </div>

                                    <div className="d-flex">
                                        <h6 className="mb-0 ">Portfolio & Use cases</h6>
                                        <span className="ms-2">🤘</span>
                                    </div>
                                </div>


                                <div className="bg-mode border p-3 rounded-3 rounded-start-top-0 d-flex align-items-center mb-3">

                                    <div className="avatar avatar-xs me-3">
                                        <a href="!#"> <img className="avatar-img rounded-circle" src="https://rimsan.me/images/front/09.jpg" alt="Blogs & Skills" /> </a>
                                    </div>

                                    <div className="d-flex">
                                        <h6 className="mb-0 ">Blogs & Skills </h6>
                                        <span className="ms-2">🎯</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6" style={{zIndex: 1000}}>
                            <div className="ms-4">

                                <h2 className="h1">Get Discovered</h2>
                                <p className="lead mb-4">Easily build your own profile. Create unlimited new CV and easily edit them afterwards. Effortlessly manage and track your job applications in an organized manner.</p>
                                <div className="d-flex">
                                    <a className="btn btn-primary " href="/u/about/669151fa8aecbee3a8de23f7"> Demo - Rimsan</a>
                                    <a className="btn btn-primary mx-2" href="/sign-up"> Let's start </a>
                                    <a className="" href="!#"><GoogleButton type={"icon"} text={"signup_with"} /></a>
                                </div>

                                {/**<div id="g_id_onload"
                                    data-client_id="11879444161-09985e90as4vdma50tgidvc9vlv38rt4.apps.googleusercontent.com"
                                    data-login_uri="https://www.rimsan.me/login"
                                    data-auto_prompt="false">
                                </div>
                                <div className="g_id_signin"
                                    data-type="standard"
                                    data-size="large"
                                    data-theme="outline"
                                    data-text="sign_in_with"
                                    data-shape="rectangular"
                                    data-logo_alignment="left">
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        <section className="py-4 py-sm-5">
            <div className="container ">
                <div className="row justify-content-center">

                    <div className="col-lg-12 text-center mb-5  d-md-flex justify-content-center">
                        <div className="d-md-flex" >
                            <Users />
                            <div>
                                <h2 className="h1">Easily build your own profile</h2>
                                <p>Effortlessly create and customize your own professional profile in minutes. </p>
                            </div>
                        </div>

                    </div>
                </div>

       

                <div className="row g-4 g-lg-5">

                    <div className="col-md-4 text-center">
                        <img className="h-100px mb-4" src="https://rimsan.me/images/core/1.png" alt="" />

                        <h4>100% Free</h4>
                        <p className="mb-0">A platform offering users 100% free accounts to create, collaborate, and showcase their portfolios.</p>
                    </div>

                    <div className="col-md-4 text-center">
                        <img className="h-100px mb-4" src="https://rimsan.me/images/core/2.png" alt="" />
                        <h4>Help each others</h4>
                        <p className="mb-0">A collaborative platform where professionals help each other build and showcase their portfolios effectively.</p>
                    </div>

                    <div className="col-md-4 text-center">
                        <img className="h-100px mb-4" src="https://rimsan.me/images/core/3.png" alt="" />
                        <h4>Be Creative</h4>
                        <p className="mb-0">A platform where creative thinking and collaborative work come together to craft exceptional portfolios.</p>
                    </div>

                </div>

            </div>
        </section>
        <section className="py-4 py-sm-5 ">
            <div className="container">
                <div className="card card-body shadow-none bg-white border-0 p-4 p-sm-5 text-center">
                    <div className="col-lg-8 mx-auto">

                        <h2 className="h1">Founder</h2>
                        <p className="lead mb-4">"Being keen to learn is key to success." </p>

                        <a href="/u/about/669151fa8aecbee3a8de23f7">
                            <div className="avatar avatar-xxl">
                                <img className="avatar-img rounded-circle bg-primary border border-white border-3" alt="" src="https://rimsan.me/images/images-profile.png" />
                            </div>
                        </a>

                        <ul className="nav nav-divider justify-content-center mt-4">
                            <li className="nav-item"> Moh'd Rimsan </li>

                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </>)
}